<template>
  <section style="background-color: white">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div>
            <img
              src="/assets/images/feed-form.png"
              class="img-fluid feed-form-img"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <div
              id="contact-form"
              class="contact-form"
              style="padding: 130px 0px 65px 0px"
            >
              <h1 class="contact-form_title">Contact Form</h1>
              <div class="separator"></div>

              <!-- <div v-if="isSending" class="loading">Sendig...</div> -->

              <form class="form" @submit.prevent="onSubmit">
                <input
                  required
                  name="name"
                  v-model="contact.name"
                  placeholder="Name"
                  type="text"
                  autocomplete="off"
                  v-validate="'required|min:3|max:20'"
                />
                <div
                  v-if="submitted && errors.has('name')"
                  class="alert-danger"
                >
                  {{ errors.first("name") }}
                </div>
                <input
                  required
                  name="email"
                  v-model="contact.email"
                  placeholder="E-mail"
                  type="email"
                  autocomplete="off"
                  v-validate="'required|email|max:50'"
                />
                <div
                  v-if="submitted && errors.has('email')"
                  class="alert-danger"
                >
                  {{ errors.first("email") }}
                </div>
                <input
                  required
                  name="phone"
                  v-model="contact.number"
                  placeholder="Phone"
                  type="number"
                  autocomplete="off"
                  v-validate="'required|min:10|max:10'"
                />
                <div
                  v-if="submitted && errors.has('phone')"
                  class="alert-danger"
                >
                  {{ errors.first("phone") }}
                </div>
                <textarea
                  required
                  name="message"
                  v-model="contact.textareavalue"
                  rows="4"
                  type="text"
                  autocomplete="off"
                  v-validate="'required|min:3|max:100'"
                  placeholder="Message"
                ></textarea>
                <div
                  v-if="submitted && errors.has('message')"
                  class="alert-danger"
                >
                  {{ errors.first("message") }}
                </div>
                <button class="button" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span
                  ><span>Send</span>
                </button>
              </form>
              <!-- <div v-if="message == 'Success' ">
                      <Alert :alertType="info" :length="2" :message="message" ></Alert>
                  </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import Alert from '../components/Alert.vue'
import userService from "../services/user.service";
import config from "../config";
export default {
  data() {
    return {
      contact: {
        name: "",
        email: "",
        textareavalue: "",
        number: "",
      },
      submitted: false,
      successful: false,
      loading: false,
      message: "",
    };
  },
  // components:{
  //     Alert
  // },

  methods: {
    onSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;
          userService
            .sendFeedBack(this.contact)
            .then((response) => {
              if (response.data.Message == "Success")
                this.makeToast("success", "Feedback submitted successfully");
              this.loading = false;
              setTimeout(
                () => this.$router.push({ path: "/" }),
                config.pageRedirectDelay * 1000
              );
            })
            .catch((e) => {
              let resMessage = e.response.data.Message;
              this.makeToast("danger", resMessage);
              this.loading = false;
            });
        }
      });
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: config.toastDelay,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
};
</script>
<style scoped>
body {
  background: #f1f1f1;
  font-family: "Roboto", sans-serif;
}

.contact-form {
  font-family: 16px;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

.contact-form .separator {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.contact-form .form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form_title {
  color: #333;
  text-align: left;
  font-size: 28px;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="number"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 10px 7px;
  margin-bottom: 15px;
  outline: none;
}

.contact-form textarea {
  resize: none;
}

.contact-form .button {
  background: #0094da;
  border: solid 1px #0094da;
  color: white;
  cursor: pointer;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
}

.contact-form .button:hover {
  background: #050029;
  border: solid 1px #050029;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea,
.contact-form .button {
  font-size: 14px;
  border-radius: 3px;
}
textarea {
  color: grey;
}
input[type="number"] {
  font-size: 14px;
}
.alert-danger {
  color: #cb0013;
  background-color: #ffffff00;
  border-color: #f5c6cb00;
  margin: -12px 0px 10px 0px;
  font-size: 14px;
}
</style>
